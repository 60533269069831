<!--购物车-->
<template>
  <div class="page-cart">
    <Breadcrumb :data="[
      {
        name:'Cart',
      }
    ]"></Breadcrumb>

    <div class="common-gap"></div>

    <div class="bottom-content">

      <table class="cart-table">
        <thead>
        <tr>
          <th style="text-align: left" class="checkbox">
            <el-checkbox class="select-all"
                         :disabled="!CanCheck"
                         :indeterminate="isIndeterminate"
                         v-model="checkAll"
                         @change="handleCheckAllChange">Select All
            </el-checkbox>
          </th>
          <th>Product</th>
          <!--          <th>Price</th>-->
          <th>Count</th>
          <th>Options</th>
        </tr>
        </thead>
        <el-checkbox-group
          class="tbody"
          style="width: 100%"
          v-model="checkedCities"
          @change="handleCheckedCitiesChange">
          <tr :key="good.productId" class="tr" v-for="good in DataList">
            <td class="td checkbox">
              <el-checkbox :label="good.productId" :key="good.productId">

              </el-checkbox>
            </td>
            <td class="td">
              <div class="store-info">
                <el-image
                  class="image"
                  :src="good.product.url"
                  fit="contain"></el-image>
                <div class="_right">
                  <div class="name">{{ good.product.productName }}</div>
                  <div class="sub-title">{{ good.product.productSubTitle }}</div>
                  <div class="price-row">

                    <template v-if="good.product.productInPromotion==='Y'">
                      <div class="price">
                        ${{ good.product.productPromotionPrice / 100 }}
                      </div>

                      <div class="org-price">
                        ${{ good.product.productPrice / 100 }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="price">
                        ${{ good.product.productPrice / 100 }}
                      </div>
                    </template>
                  </div>
                </div>


              </div>
            </td>
            <td class="td">

              <div class="input-content">
                <el-input-number
                  step-strictly
                  :min="0"
                  :step="good.product.productMinNum"
                  :max="1000000000"
                  style="width: 100%"
                  v-model="good.amount"
                  @change="handleAdd(good)"
                ></el-input-number>
              </div>


            </td>
            <td class="td align-center">

              <el-button @click="deleteCart(good.productId)" type="text">Delete</el-button>

            </td>
          </tr>
        </el-checkbox-group>
      </table>

      <el-empty v-if="DataList.length===0" description="No Data"></el-empty>

      <div class="address-box">

        <div class="title">
          <i class="el-icon-location-outline"></i>
          <span>Address</span>
        </div>

        <el-radio-group class="address-list" v-model="selectedAddressID">
          <div class="address-row" :key="item.id" v-for="item in addressList">
            <el-tooltip :disabled="item.address.length<100" effect="light" class="item" :content="`${item.address}   email: ${ item.email }`"
                        placement="top-end">
              <el-radio :label="item.id">

                <span class="address-text">{{ item.address }}  email: {{ item.email }}</span>

              </el-radio>
            </el-tooltip>
          </div>
        </el-radio-group>

        <div class="add-address">
          <el-button @click="addAddress" type="text">Add Address</el-button>
        </div>


      </div>
      <div class="bottom-row">

        <div class="_left">
          <el-checkbox
            :disabled="!CanCheck"
            class="checkbox" :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange">Select All
          </el-checkbox>

          <el-button :disabled="!CanSubmit" @click="delStore" type="text">Delete Selected Item</el-button>

        </div>
        <div class="_right">
          <el-button class="subnmit" :disabled="!CanSubmit" @click="submit" type="primary">Submit</el-button>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { address } from '@/api/user'
import { deleteItem } from '@/api/cart'

export default {
  components: { Breadcrumb },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      cartList: [],
      checkedCities: [],
      selectedAddressID: '',
      addressList: [],
    }
  },
  created() {
    //查询购物车 ，获取地址list
    this.cartList = this.$store.dispatch('queryCart');
    this.queryAdd()
  },
  methods: {
    async submit() {

      const that = this;

      // 构造下单参数
      const items = this.checkedCities.map((id) => {
        const tg = this.cartList.find(item => item.productId === id)
        return {
          amount: tg.amount,
          productId: tg.productId,
        }
      })

      // 下单
      await this.$store.dispatch('submit', {
        contactId: this.selectedAddressID,
        items: items
      })
      this.$message.success('Submit succeed!')

      // 成功去订单页面
      setTimeout(function () {

        that.$router.push({
          name: 'order'
        })
      }, 2000)

    },
    // 查询地址
    async queryAdd() {
      const res = await address();
      this.addressList = res.data
      if (this.addressList.length > 0) {
        this.selectedAddressID = this.addressList[0].id
      }

    },
    //删除商品 批量
    delStore() {
      console.log('del')
      this.$store.dispatch('deleteItem', this.checkedCities)
    },
    // 删除商品 单个
    deleteCart(id) {
      this.$store.dispatch('deleteItem', [id])
    },
    // 修改商品
    handleAdd(value) {
      console.log('add', value)
      if (value.amount === 0) {
        this.$store.dispatch('deleteItem', [value.productId]);

        // 删除商品后去掉筛选
        this.checkedCities = this.checkedCities.filter(item => item !== value.productId)

      } else {
        this.$store.dispatch('modifyItem', {
          productId: value.productId,
          amount: value.amount
        });
      }


    },
    // 去地址维护
    addAddress() {
      this.$router.push({
        path: 'address'
      })
    },
    handleCheckAllChange(value) {
      this.checkedCities = value ? this.cartList.map((item) => item.productId) : [];
      this.isIndeterminate = false;
    },

    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkedCities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cartList.length;
    }
  },

  computed: {
    CanSubmit() {
      return this.checkedCities.length > 0 && this.selectedAddressID
    },
    CanCheck() {
      return this.cartList.length > 0
    },
    DataList: function () {
      return this.$store.state.cartList
    }
  },
  watch: {
    $route() {
      this.queryAdd()
    },
    DataList(value) {
      this.cartList = value
    }
  }
}
</script>

<style lang="less" scoped>

.bottom-content {
  background: var(--main-color-bg-gray);
  padding: 0 75px 75px;
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  tr {
    height: 200px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 3px 3px 7px 0 rgba(198, 198, 198, 0.3);
    border-radius: 12px;
    margin-bottom: 10px;
  }

  td {
    vertical-align: middle;
  }

  thead {

    tr {
      height: 64px;
    }

    th {
      font-size: 20px;
      //text-align: left;
    }
  }

  .tbody {
    display: table-row-group;
  }
}

.address-box {
  padding: 20px 30px;
  margin-top: 20px;
  background: #FAFCFF;
  border: 1px solid #F5F5F5;
  box-shadow: 3px 3px 7px 0 rgba(198, 198, 198, 0.3);

  /deep/ .el-radio {
    max-width: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .title {
    display: flex;
    align-items: center;

    font-size: 22px;
    font-weight: 400;

  }

  .address-list {
    padding-left: 5px;
    margin-top: 20px;
  }

  .address-text {
    font-size: 22px;

  }

  .address-row {
    margin-bottom: 20px;
  }

  .add-address {
    padding-left: 24px;
    font-size: 22px;

  }

  /deep/ .el-button--text {
    font-size: 22px;
    color: #43328F;
  }

}

.store-info {
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  align-items: center;

  .image {
    //background-color: #ccc;
    width: 230px;
    height: 154px;
  }


  .price-row {
    margin-top: 20px;
    display: flex;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    line-height: 43px;
  }


  ._right {
    margin-left: 50px;

  }

  .sub-title {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }

  .price {
    font-style: italic;
    font-size: 46px;
    font-family: Poppins-Medium,ArialMT,serif;
    font-weight: 400;
    color: #333333;
    line-height: 43px;
  }

  .org-price {
    margin-left: 20px;
    font-family: Poppins-Medium,ArialMT,serif;
    font-size: 36px;
    font-weight: normal;
    color: #868686;
    line-height: 43px;
    position: relative;

    &:before {
      transform: rotate(10deg) translateX(-10%);
      border-radius: 3px;
      border-top: 4px solid var(--main-color-red);
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 50%;
      width: 120%;
      height: 3px;
    }
  }

}

.align-center {
  text-align: center;
}

.checkbox {
  padding-left: 20px;
}

.input-content {
  width: 200px;
  margin: 0 auto;
}

/deep/ .el-icon-location-outline {
  margin-right: 20px;
  font-size: 30px;
  color: var(--main-color-blue);
}

.bottom-row {
  font-size: 22px;
  font-weight: 400;
  padding: 0 80px 0 20px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  background: #FAFCFF;
  border: 1px solid #F5F5F5;
  box-shadow: 3px 3px 7px 0px rgba(198, 198, 198, 0.3);

  /deep/ .el-checkbox__label {
    font-size: 22px;
    font-weight: 400;
  }

  /deep/ .el-button--text {
    font-size: 22px;
  }

  ._left {

    .checkbox {
      margin-right: 40px;
    }

  }

  ._right {

  }
}

.subnmit{
  font-size: 22px;
}

.page-cart {
  min-height: calc(100vh - 12.5rem);

  /deep/ .el-checkbox {

    .el-checkbox__inner {
      width: 20px;
      height: 20px;

      &:after {
        height: 11px;
        left: 7px;
      }
    }

    //transform: scale(1.5);
  }
}

.select-all {
  /deep/ .el-checkbox__label {
    font-size: 20px;
    font-weight: bold;
  }
}

/deep/ .el-checkbox__label {
  display: none;
}

</style>
